* {
  font-family: "Inter", sans-serif;
  font-feature-settings: "liga" 1, "calt" 1; /* fix for Chrome */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  outline: none !important;
}

@supports (font-variation-settings: normal) {
  * {
    font-family: "InterVariable", sans-serif;
  }
}

code {
  font-family: "Inter", source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.app.light{
  background-color: #f8f9fa;
}

.app.dark{
  background-color: #121212;
}

@media (min-width: 768px) {
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .min-vh-md-100 {
    min-height: 99.96vh !important;
  }
}

a.light {
  color: black !important;
  text-decoration: none !important;
}

a.dark {
  color: white !important;
  text-decoration: none !important;
}

h2.light,
h3.light {
  color: #212529;
}

h2.dark,
h3.dark {
  color: #f8f9fa;
}

p.light {
  color: #212529;
}

p.dark {
  color: #f8f9fa;
}

.skill-name.light {
  color: #212529;
}

.skill-name.dark {
  color: #f8f9fa;
}

.card.light {
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

.card.dark {
  padding: 20px;
  background-color: #282828; /* Lighten the background color */
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5); /* Darken the box shadow color */
  transition: 0.3s;
}

.card.light:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.card.dark:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.5);
}

.card-hovered .card:not(:hover) {
  opacity: 0.4;
}

.card-body {
  padding: 20px;
}

.card-title.light {
  color: #212529;
}

.card-title.dark {
  color: #f8f9fa;
}

.card-subtitle.light {
  color: #6c757d;
}

.card-subtitle.dark {
  color: #8B8B8B;
}

.card-text.light {
  color: #6c757d;
}

.card-text.dark {
  color: #8B8B8B;
}

.skill-tag.light {
  display: inline-block;
  padding: 5px 10px;
  margin: 5px;
  border-radius: 50px;
  background-color: #bebebe;
  color: #212529;
  font-size: 14px;
  font-weight: bold;
}

.skill-tag.dark {
  display: inline-block;
  padding: 5px 10px;
  margin: 5px;
  border-radius: 50px;
  background-color: #515151;
  color: #f8f9fa;
  font-size: 14px;
  font-weight: bold;
}

.nav-brand-dark,
.nav-brand-light,
.nav-link-dark,
.nav-link-light {
  cursor: pointer;
}

.nav-brand-light {
  color: #212529;
}

.nav-brand-dark {
  color: #f8f9fa;
}

.nav-link-light {
  position: relative;
  color: #212529;
  text-decoration: none;
  transition: color 0.3s;
}

.nav-link-dark {
  position: relative;
  color: #f8f9fa;
  text-decoration: none;
  transition: color 0.3s;
}

.nav-link-light::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #212529;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.nav-link-dark::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #f8f9fa;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.nav-link-dark:hover::before,
.nav-link-light:hover::before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.nav-link-dark.active,
.nav-link-light.active {
  font-weight: bold;
}

.nav-link-dark.active::before,
.nav-link-light.active::before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.sticky-header-container {
  position: sticky;
  top: 0;
  z-index: 100;
}

.sticky-header-light {
  position: relative;
  text-indent: 24px;
  left: -24px;
  width: calc(100% + 48px);
  background: rgba(248, 249, 250, 0.7);
  backdrop-filter: blur(2px);
}

.sticky-header-dark {
  position: relative;
  text-indent: 24px;
  left: -24px;
  width: calc(100% + 48px);
  background: rgba(18, 18, 18, 0.7);
  backdrop-filter: blur(2px);
}

.sticky-top-container {
  position: sticky;
  top: 0;
  z-index: 9999;
}

.sticky-top-switch {
  position: fixed;
  top: 1.2rem;
  right: calc(var(--bs-gutter-x) * 0.5 + 20px);
}

@media (min-width: 576px) {
  .sticky-top-switch {
    right: calc((100vw - 540px) / 2 + var(--bs-gutter-x) * 0.5 + 10px);
  }
}

.ranger {
  font-weight: bold;
}

@keyframes gradient {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: -100%;
  }
}

.ranger:hover {
  cursor: url("../public/nyr.png"), auto;
  background: linear-gradient(
    270deg,
    #ce1126,
    #ffffff,
    #0038a8,
    #ce1126,
    #ffffff,
    #0038a8,
    #ce1126
  );
  background-size: 200% 200%;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradient 0.5s linear infinite;
}

.form-check-label.light {
  text-align: right;
  user-select: none;
  color: #212529;
}

.form-check-label.dark {
  text-align: right;
  user-select: none;
  color: #f8f9fa;
}

.form-check-input {
  box-shadow: none !important;
}

.form-check-input,
.form-check-input:focus {
  border-color: #212529 !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23212529'/%3e%3c/svg%3e") !important;
}

.form-check-input:checked:focus::after {
  background-image: none !important;
  background-color: #212529 !important;
}

.form-check-input:checked {
  background-color: #121212 !important;
  border-color: #f8f9fa !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23f8f9fa'/%3e%3c/svg%3e") !important;
}

.fa-link {
  transform: rotate(-180deg) scale(1);
  transition: transform 0.3s ease-in-out;
}

.card:hover .fa-link,
a:hover .fa-link{
  transform: rotate(180deg) scale(1.15);
}

.small-project {
  position: relative;
  display: inline-block; /* Ensure the pseudo-element aligns correctly */
  overflow: hidden; /* Keeps the pseudo-element within the parent's bounds */
}

.small-project::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%; /* Start from the middle */
  width: 0%; /* Initially hidden */
  height: 2px; /* Thickness of the underline */
  background-color: currentColor; /* Use the text color for the underline */
  transition: all 0.3s ease; /* Smooth transition for the animation */
  transform: translateX(-50%) scaleX(0); /* Start scaled to 0 from the center */
}

.small-project:hover::after {
  width: 100%; /* Full width on hover */
  transform: translateX(-50%) scaleX(1); /* Scale to full size from the center */
}

.small-project-link{
  font-size: 0.8em;
  margin-left: 0.5em;
  position: relative;
  bottom: 2px;
}